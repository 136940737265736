export default {
  message: {
    hello: "你好",
  },
  label:{
    addOrder:"添加订单",
    addType:"添加分类",
    addGoods:"添加商品",
    addBanner:"添加轮播图",
    addVipPrice:"添加会员价格"
  },
  nice: "好",

  leftside:{
    user_center:'用户中心'
  }
};
